<template>
  <ArgonSnackbar
    :isOpen="isError.error || isSuccess.success"
    :title="isError.error ? isError.msg : isSuccess.msg"
    :color="isError.error ? 'danger' : 'success'"
    :timeOut="10000"
    @close="
      isError.error = false;
      isSuccess.success = false;
    "
  />
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="card-body">
                  <div class="text-center mb-3">
                    <img width="150" src="@/assets/img/modal_logo_dark.png" alt="" />
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12 mt-2" v-if="isPageLoaded">
                      <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
                      <form role="form" @submit.prevent="handleSubmit" class="text-start" v-if="showForm">
                        <!-- <div class="my-3 fs-5 fw-bold text-dark ">Reset your password</div> -->
                        <div class="my-3 fs-6 text-dark text-center">
                          For: <span class="fw-bold">{{ userEmail }}</span>
                        </div>
                        <div class="mt-4 mb-1">
                          <input id="password" type="password" class="form-control" placeholder="Enter password" v-model="password" required />
                        </div>

                        <!-- <div class="error text-sm text-danger">{{ error }}</div> -->

                        <div class="text-center">
                          <argon-button color="success" variant="gradient" full-width class="my-4 mb-2" :disabled="password == ''">
                            <span v-if="isSigningIn"> Saving... <i class="fa fa-circle-o-notch fa-spin"></i> </span>
                            <span v-else> Save </span>
                          </argon-button>
                        </div>
                      </form>
                      <div v-else class="text-center my-3">
                        <h5>Try resetting your password again</h5>
                        <p>Your request to reset your password has expired or the link has already been use</p>
                      </div>
                    </div>
                    <div v-else style="height: 250px">
                      <Loading />
                    </div>
                  </div>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <router-link :to="{ name: 'Content' }" class="mx-auto mb-4 text-sm"> Login into your account </router-link>
                </div>
              </div>
            </div>
            <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden login-bg-container">
                <span class="mask bg-gradient-success opacity-0 bg-gradient-success-login"></span>

                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Knowledge grows exponentially. The more we know, the greater our ability to learn, and the faster we expand our knowledge base"</h4>
                <p class="text-white position-relative">~Dan Brown</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ForgotPassword from '@/components/custom/ForgotPassword.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import { useRoute, useRouter } from 'vue-router';
import ArgonSnackbar from '@/components/ArgonSnackbar.vue';
import firebase from 'firebase/app';
import { ref } from 'vue';
import { projectFirestore, projectAuth, projectStorage, functions } from '@/firebase/config';
import Loading from '@/components/custom/Loading.vue';
import useLogout from '@/composables/useLogout';
import { httpsCallable } from '@firebase/functions';
export default {
  name: 'ResetPassword',
  components: {
    ForgotPassword,
    ArgonButton,
    ArgonSnackbar,
    Loading,
  },
  data() {
    return {};
  },
  setup() {
    const showForm = ref(false);
    const userEmail = ref('');
    const isSigningIn = ref(false);
    const password = ref('');
    const route = useRoute();
    const isPageLoaded = ref(false);
    const { logout } = useLogout();
    const router = useRouter();
    const isError = ref({
      error: false,
      msg: '',
    });
    const isSuccess = ref({
      success: false,
      msg: '',
    });
    if (route.query.oobCode) {
      // console.log(this.$route.query.oobCode)
      firebase
        .auth()
        .verifyPasswordResetCode(route.query.oobCode)
        .then(email => {
          showForm.value = true;
          userEmail.value = email;
          console.log('userEmail', userEmail);
          // Display a "new password" form with the user's email address
        })
        .catch(err => {
          showForm.value = false;
          console.log('error', err);
        })
        .finally(() => {
          isPageLoaded.value = true;
        });
    }

    const handleSubmit = async () => {
      isError.value = {
        error: false,
        msg: '',
      };
      isSuccess.value = {
        success: false,
        msg: '',
      };
      if (password.value.length < 5) {
        isError.value = {
          error: true,
          msg: 'Password must be at least 6 characters in length',
        };
        isSigningIn.value = false;
        return;
      }
      isSigningIn.value = true;
      await firebase
        .auth()
        .confirmPasswordReset(route.query.oobCode, password.value)
        .then(res => {
          const updatePassword = functions.httpsCallable('updatePassword');
          updatePassword({
            email: userEmail.value,
            password: password.value,
          })
            .then(res => {
              console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
          console.log('res', res);
          isSuccess.value = {
            success: true,
            msg: 'Password updated successfully',
          };
          password.value = '';
          setTimeout(() => {
            logout();
            router.push({ name: 'Admin' });
          }, 500);
        })
        .catch(err => {
          console.log('err', err);
          isError.value = {
            error: true,
            msg: 'Error while reseting password',
          };
        })
        .finally(() => {
          isSigningIn.value = false;
        });
    };
    return {
      showForm,
      userEmail,
      handleSubmit,
      isSigningIn,
      password,
      isPageLoaded,
      isError,
      isSuccess,
    };
  },
  mounted() {},
  methods: {},
};
</script>
