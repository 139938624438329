<template>
  <div>
    <!-- theme default -->
    <div v-if="theme === 'blackDiamond'">
      <div class="d-flex justify-content-center align-items-center">
        <i class="fa fa-spinner fa-spin fa-4x" style="color: #65ca8f"></i>
      </div>
    </div>
    <!-- theme classic -->
    <div v-else>
      <div class="d-flex justify-content-center align-items-center content-loading-ico">
        <i class="fa-duotone fa-gear fa-spin fa-fast-spin fa-2x text-black" style="color: var(--primaryColor)"></i>
      </div>
      <div class="text-center">
        <p class="content-loading-process" style="color: var(--primaryColor)">Processing</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'DefaultLoading',
  props: ['theme'],
};
</script>

<style>
.fa-fast-spin {
  animation: spin 1.5s linear infinite;
}

.fa-fast-spin1 {
  animation: spin1 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
