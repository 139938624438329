<template>
  <template v-if="isInit && $store.state.advancedWidgetActiveRoute == 'HomeAdvancedDashboard'">
    <!-- Widget Header -->
    <template v-if="themeValue == 'compact' && $store.state.showCompactBottomToolbar">
      <header class="ps-3 pt-2 pb-3 border-bottom">
          <div class="container container-widget">
            <div class="row align-items-center ">
              <div class="text-left col-6">
                <h3 class="my-0" style="color:#000000">{{ advancedWidgetHomeHeading1 }}</h3>
              </div>
              <div class="col-6 d-flex px-0 text-end align-items-center justify-content-end" style="font-size: 15px">
              <div class=" mx-4">
                <!-- <img v-if="$store.state.companyLogo" :src="`${$store.state.companyLogo}?cb=${timestamp}`" style="width: 30px; height: 30px; object-fit: contain;" class="mx-2 ms-auto d-inline-block" alt="" /> -->
                <span class="bg-light rounded-circle d-inline-block mx-2 d-flex align-items-center text-dark justify-content-center" style="height: 30px; width: 30px">{{ $store.state.companyName.charAt(0) }}</span>
              
              </div> <!-- {{ $store.state.companyName }} -->
              
            </div>
          </div>
        </div>
      </header>
    </template>
    <template v-if="!$store.state.showCompactBottomToolbar && $store.state.compactWidgetActiveRoute == 'HomeAdvancedDashboard'"> 
      <header class="leftrightpad">
        <div class="container container-widget nopadding">
          <div class="row justify-content-center align-items-center pt15">
            <button class="position-absolute previous-btn-widget" v-if="themeValue == 'compact' || ($store.state.advancedWidgetIndex !== 'HomeAdvancedDashboard')" @click="themeValue == 'compact' ? $store.state.showCompactBottomToolbar = true : goBack()">
                <svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                </svg>
              </button>
            <div class="col-sm-12 text-center p-3">
              <div class="logo-widget-advanced">
                <a v-if="siteIcon" href="javascript:void(0)">
                  <img :src="siteIcon" alt="" width="100">
                </a>
              </div>
            </div>

          </div>
        </div>
        <div>
          <div class="container container-widget">
            <div class="row">
              <div class="col-sm-12 text-left">
                <h1>{{ advancedWidgetHomeHeading1 }} <br> {{ advancedWidgetHomeHeading2 }}</h1>
              </div>
            </div>
          </div>


        </div>
      </header>


      <!-- Widget Content  -->

      <div class="widget-advanced-content">
        <div class="container container-widget nopadmar">
          <div class="row justify-content-center align-items-center">

            <div class="col-sm-12 p-3 pb-0 nopadbottom" v-if="widgetHelpLibrary"  :class="{ 'mb75': !widgetMessages }">
              <div class="search-for-help-block">
                <button @click="$router.push({ name: 'HomeAdvancedHelp' })">
                  <p><strong>Search for Help</strong></p>
                  <i class="search-icon-content searchglass">

                    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="7.5" cy="7.5" r="4.625" stroke="currentColor" stroke-width="1.75"></circle>
                      <path
                        d="M13.3813 14.6187C13.723 14.9604 14.277 14.9604 14.6187 14.6187C14.9604 14.277 14.9604 13.723 14.6187 13.3813L13.3813 14.6187ZM10.3813 11.6187L13.3813 14.6187L14.6187 13.3813L11.6187 10.3813L10.3813 11.6187Z"
                        fill="currentColor"></path>
                    </svg>
                    <!-- <svg viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M7,15V17H9C9.14,18.55 9.8,19.94 10.81,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V13.03C19.85,11.21 17.82,10 15.5,10C14.23,10 13.04,10.37 12.04,11H7V13H10C9.64,13.6 9.34,14.28 9.17,15H7M17,9V7H7V9H17Z" />
                    </svg>
                    -->
                  </i>
                </button>

                <div class="featured-articles hometab">
                  <ul>
                    <template v-if="isContentLoading">
                      <li class="px-2 mb-2" v-for="i in 5" :key="i">
                        <Skeleton height="2rem" class="mb-2" borderRadius="10px"></Skeleton>
                      </li>
                    </template>
                    <template v-else>
                      <template v-if="categories.length > 0">
                        <li v-for="category in categories">
                          <router-link :to="{ name: 'HomeAdvancedHelp2', params: { categoryId: category.category_title } }"
                            class="d-flex align-items-center">
                            {{ initTranslations(category.category_title) }}
                          </router-link>
                          <i class="search-icon-content d-flex align-items-center">
                            <svg viewBox="0 0 24 24">
                              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                            </svg></i>
                        </li>
                      </template>
                      <template v-else>
                        <li>No Content found </li>
                      </template>
                    </template>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-12 p-3 pb-0 nopadbottom" v-if="showChat && widgetMessages" :class="{ 'mb75': !widgetContactUs && !widgetNewsFeed }" >
              <div class="chat-with-us chat-message-block shadow-sm-hometab-boxes">
                <router-link :to="$store.state.isModalChat ? `chatmodal?parentURL=${parentURL}&parent_lib_id=${$store.state.parent_lib_id}`  : `chat?parentURL=${parentURL}&parent_lib_id=${$store.state.parent_lib_id}` ">
                  <p><strong>Chat with us now!</strong></p>
                  <p>We are online! How can we help you?</p>
                  <i class="chat-icon" aria-hidden="true">
                    <svg viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3M17,12V10H15V12H17M13,12V10H11V12H13M9,12V10H7V12H9Z" />
                    </svg>
                  </i>
                </router-link>
              </div>
            </div>




            <div class="col-sm-12 p-3 pb-0 nopadbottom" v-if="isContactFormOnline && widgetContactUs"  :class="{ 'mb75': !widgetNewsFeed }">
              <div class="msg-with-us shadow-sm-hometab-boxes">
                <router-link :to="{ name: 'HomeAdvancedMsg' }">

                  <p><strong>Send us a message</strong></p>
                  <p>We are online and ready to help.</p>
                  <!-- <i class="fa-solid fa-angle-right "></i> -->
                  <i class="msg-icon" aria-hidden="true">
                    <svg viewBox="0 0 24 24">
                      <path fill="currentColor" d="M3 20V14L11 12L3 10V4L22 12Z" />
                    </svg>
                  </i>

                </router-link>
              </div>
            </div>


            <div class="col-sm-12 p-3 nopadbottom" v-if="widgetNewsFeed">
              <div v-if="isContentLoading" v-for="i in 2" class="news-updates news-updates-block mb10">
                <div class="search-for-help-block">
                  <button>
                    <p><strong>
                        <Skeleton height="2rem" class="mb-2" width="200px" borderRadius="10px"></Skeleton>
                      </strong></p>
                    <i class="search-icon-content mt-2 me-2">
                      <Skeleton height="2rem" width="30px" borderRadius="10px"></Skeleton>
                    </i>
                  </button>
                  <ul>
                    <li>
                      <div class="featured-image">
                        <a href="javascript:void(0)">
                          <span>
                            <strong>
                              <Skeleton height="173px" class="mb-2 mb-3 mx-auto" width="75%" borderRadius="10px"></Skeleton>
                            </strong>
                          </span>
                          <Skeleton height="1.5rem" class="mb-2" width="100%" borderRadius="10px"></Skeleton>
                          <Skeleton height="1.2rem" class="mb-1" width="100%" borderRadius="10px"></Skeleton>
                          <Skeleton height="1.2rem" class="mb-1" width="100%" borderRadius="10px"></Skeleton>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <template v-else>
                <template v-if="newsFlash.length">
                  <div v-for="news in newsFlash" class="news-updates news-updates-block mb10 overflow-hidden">
                    <router-link :to="{ name: 'HomeAdvancedNewsFlash', params: { id: news.id } }"
                      class="search-for-help-block p-0">


                      <div class="col-sm-12 nopadbottom padtop0">
                        <div class="featured-image newshome">
                          <div class="imgoverflow">
                            <a href="javascript:void(0)" class="home-featured-news-img">
                              <img :src="news.newsFlash_featureImage" alt="Featured News">
                            </a>
                          </div>
                        </div>
                      </div>


                      <div class="col-sm-12 p-3 nopadbottom padtop0">
                        <div class="featured-image newshome">
                          <p class="featured-news-category">{{
                            initTranslations(news.categoryName)
                          }}
                          </p>
                          <router-link :to="{ name: 'HomeAdvancedNewsFlash', params: { id: news.id } }"
                            class="news-block-new p-0">
                            <p><strong>{{
                              initTranslations(news.newsFlash_title)
                            }}</strong></p>
                            <p class="newscontent">
                            <div v-html="initTranslations(news.newsFlash_content)" style="height:38px"
                              class="overflow-hidden"></div>
                            </p>
                            <i class="search-icon-content d-flex align-items-center">
                              <svg viewBox="0 0 24 24">
                                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                              </svg></i>

                          </router-link>
                        </div>
                      </div>
                      <!-- 
                      <ul>
                        <button>
                        <p><strong>{{ initTranslations(news.categoryName) }} </strong></p>
                        <i class="search-icon-content">
                          <svg viewBox="0 0 24 24">
                            <path fill="currentColor"
                              d="M5.8 21L7.4 14L2 9.2L9.2 8.6L12 2L14.8 8.6L22 9.2L18.8 12H18C17.3 12 16.6 12.1 15.9 12.4L18.1 10.5L13.7 10.1L12 6.1L10.3 10.1L5.9 10.5L9.2 13.4L8.2 17.7L12 15.4L12.5 15.7C12.3 16.2 12.1 16.8 12.1 17.3L5.8 21M17 14V17H14V19H17V22H19V19H22V17H19V14H17Z" />
                          </svg>
                        </i>
                      </button> -->
                      <!-- <li>
                          <div class="featured-image">
                            <a href="javascript:void(0)">
                              <img :src="news.newsFlash_featureImage" alt="Featured News">
                              <span><strong>{{
                                initTranslations(news.newsFlash_title)
                              }}
                                </strong></span>
                              <div v-html="initTranslations(news.newsFlash_content)" style="height:38px"
                                class="overflow-hidden"></div>
                            </a>
                          </div>
                        </li> 

                      </ul>
                      -->
                    </router-link>
                  </div>
                </template>
                <template v-else>
                  <div class="news-updates news-updates-block mb10">
                    <div class="search-for-help-block">
                      <button class="text-center">
                        No News Found
                      </button>
                    </div>
                  </div>
                </template>
              </template>
            </div>

          </div>
        </div>
      </div>
    </template>
  </template>
</template>
<script>
import '@/assets/scss/home.scss';
import { useRoute, useRouter } from 'vue-router';
import fgetUserAndHlptCategoriesAndArticles from "@/composables/front/fgetUserAndHlptCategoriesAndArticles";
import AppFooter from "@/components/custom/Footer.vue";
import Loading from "@/components/custom/Loading.vue";
import HomeContactUs from "../components/HomeContactUs.vue";
import HomeFeedback from '../components/HomeFeedback.vue';
import { getUser, lastarticleId } from "@/composables/getUser"
import getCustomCss from "@/composables/getCustomCss"
import getUserActiveSupportCategories from '@/composables/getUserActiveSupportCategories'
import { projectFirestore, timestamp } from '@/firebase/config'
import firebase from 'firebase/app'
import getUserInfo from "@/composables/getUserInfo"
import DefaultLoading from "@/components/custom/DefaultLoading.vue";
import moment from "moment";
import { useStore } from "vuex";
import { ref } from 'vue';
import getTranslations from '@/composables/getTranslations'
import fgetAllNewsFlash from '@/composables/front/fgetAllNewsFlash';
import getUserCategoryPositioning from '@/composables/getUserCategoryPositioning';

export default {
  name: "HomeAdvancedDashboard",
  components: {
    AppFooter,
    Loading,
    HomeContactUs,
    HomeFeedback,
    DefaultLoading,
  },
  props: [
    'showChat', 
    'library_only', 
    'isContactFormOnline', 
    'companyName', 
    'websiteUrl', 
    'parentURL', 
    'isAiWidget', 
    'themeValue', 
    'advancedWidgetHomeHeading1', 
    'advancedWidgetHomeHeading2', 
    'searchHeading', 
    'siteIcon', 
    'chatType',
    'isInit',
    'widgetDashboard',
    'widgetMessages',
    'widgetContactUs',
    'widgetHelpLibrary',
    'widgetNewsFeed',
    'linksLength'
  ],
  setup() {
    const route = useRoute();
    const store = useStore();
    const library_key = route.params.library_key;
    const default_support_lib_id = store.state.parent_lib_id;
    const session_id = route.params.session_id;
    const isContentLoading = ref(true);


    const router = useRouter()
    const articles = ref([])
    const categories = ref([])
    const { getNewsFlash, newsFlash } = fgetAllNewsFlash()


    const {
      allCategoriesAndArticles,
      load_articles_and_categories,
      searchResults,
      prevSearchQuery
    } = fgetUserAndHlptCategoriesAndArticles();


    const { fetchTranslation, translations } = getTranslations()
    // console.log('allCategoriesAndArticles', allCategoriesAndArticles)
    
    const { getCategoryPositioning, categoryPositioning, updateError } = getUserCategoryPositioning()


    let support_lib_idx = []
    const initData = async (library_key) => {
      await getCategoryPositioning(library_key)
      if (default_support_lib_id) {
        support_lib_idx = [library_key, default_support_lib_id]
      } else {
        support_lib_idx = [library_key]
      }
      let disabled_default_articles = []
      let disabled_default_categories = []
      await projectFirestore.collection('SupportLibraries').doc(library_key).get().then((res) => {
        if(res.exists) {
          disabled_default_articles = res.data()?.disabled_default_articles ?  res.data().disabled_default_articles : [];
          disabled_default_categories = res.data()?.disabled_default_categories ?  res.data().disabled_default_categories : [];
        }
      })
      await projectFirestore.collection('Articles').where('support_lib_id', 'in', support_lib_idx).where('is_published', '==', true).orderBy('timeStamp', 'desc').limit(20).onSnapshot((snap) => {
        // console.log('snap',snap)
        if (!snap.empty) {
          snap.docs.forEach((el) => {
            if(!disabled_default_articles.includes(el.id) && !disabled_default_categories.includes(el.data().article_categories))
            articles.value.push({ ...el.data(), id: el.id})
          })
          articles.value =  articles.value.slice(0,6)
        }
        
      })
      await getNewsFlash(library_key, 1)
      await load_articles_and_categories(library_key);
      await fetchTranslation(library_key);
      projectFirestore.collection('SupportLibraries').doc(library_key).get().then((doc) => {
        if (doc.exists) {
          // console.log('doc', doc.data().settings)
          let disabled_default_categories = []
          if (doc.data().disabled_default_categories) {
            disabled_default_categories = doc.data().disabled_default_categories
          }
          if (doc.data().categoryPositioning) {
            categories.value = doc.data().categoryPositioning.filter((el) => el.is_enabled == true).slice(0, 5)
          } else {
            projectFirestore.collection('Categories').where('support_lib_id', 'in', support_lib_idx).where('is_enabled', '==', true).onSnapshot((snap) => {
              console.log('snap', snap)
              if (!snap.empty) {

                categories.value = snap.docs.map((el) => {
                  return { ...el.data(), id: el.id }
                }).filter((el) => !disabled_default_categories.includes(el.id)).slice(0, 5)
              }
            })
          }
         
         
        }
      })
    }

    initData(library_key)




    return {
      allCategoriesAndArticles,
      load_articles_and_categories,
      searchResults,
      prevSearchQuery,
      library_key,
      store,
      default_support_lib_id,
      isContentLoading,
      translations,
      articles,
      newsFlash,
      categories
    };
  },
  data() {
    return {
      viewMode: 'all',
      selectedCategory: '',
      selectedSubCategory: '',
      selectedArticle: {},
      searchQuery: '',
      isSearching: false,
      isSearchWarningClosed: false,
      answer: '',
      isSaving: false,
      support_lib_id: '',
      qualifyUserQuestion: {},
      searchStep: {},
      steps: {},
      formSubmission: {},
      contactFormData: {},
      isDoc: false,
      stats: {},
      searchResultLength: 0,
      successArticleSearchId: '',
      isSelfManaged: false,
    };
  },
  computed: {
    isRTL() {
      return false
    }
  },
  methods: {
    goBack() {
        console.log(' this.$router?.history :- ',  this.$router)
        if(this.themeValue == 'compact') { 
            this.$store.state.showCompactBottomToolbar = true;
            this.$router.push({name:this.$store.state.advancedWidgetIndex}) 
        } else {
            this.$store.state.advancedWidgetIndex !== 'HomeAdvancedDashboard' && window.history.length > 1  && this.linksLength > 1 ? this.$router.go(-1) :  this.$router.push({name:this.$store.state.advancedWidgetIndex}) 
        }
    },
    setViewMode(view) {
      this.viewMode = view;
    },
    setCategory(category) {
      console.log(category);

      this.selectedCategory = category
      console.log('sss', this.allCategoriesAndArticles[category])
    },
    setSubCategory(subCategory) {
      this.selectedSubCategory = subCategory;
    },
    setArticle: async function (article) {
      this.selectedArticle = article;
      this.$store.dispatch('updateLastSuccessArticleSearch', { data: this.selectedArticle.id })

    },
    setStatistics: async function () {
      await projectFirestore.collection('articlesViews').get();
    },
    setWidgetViews: async function () {
      let widgetViews = {
        view: 1,
        timestamp: moment().valueOf(),
        date: moment().format('L'),
        month: moment().format('MMMM'),
        year: moment().format('YYYY'),
      }
      await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).get().then(async (doc) => {
        if (doc.exists) {
          await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('WIDGETVIEWS').add(widgetViews);
          // const snapshot = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('WIDGETVIEWS').get();
          // console.log('snapshot :-', snapshot.docs.map(doc => doc.data()));
        } else {
          console.log('doc not exists');
        }
      })
    },
    setSearches: async function () {
      let status = '';
      setTimeout(async () => {
        this.$store.dispatch('updateSearchedKeyword', this.searchQuery)
        if (this.searchResultLength > 0) {
          status = 'passed';
        } else {
          status = 'failed';
        }
        console.log(status)
        let searches = {
          view: 1,
          searchQuery: this.searchQuery,
          status: status,
          timestamp: moment().valueOf(),
          date: moment().format('L'),
          month: moment().format('MMMM'),
          year: moment().format('YYYY'),
          ticket: 0,
          reviewed: false,
          articlesReadId: [],
          articlesReadTitle: []
        }

        this.support_lib_id = this.$route.params.library_key;
        //console.log(this.support_lib_id);
        await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).get().then(async (doc) => {
          if (doc.exists) {
            console.log('doc exists')
            let res = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').add(searches);
            this.successArticleSearchId = res.id
            if (status == 'passed') {
              this.$store.dispatch('updateLastFailedArticleSearch', { data: '' })
            } else {
              this.$store.dispatch('updateLastFailedArticleSearch', { data: res.id })
            }
            // const snapshot = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').get();
            // console.log('snapshot :-', snapshot.docs.map(doc => doc.data()));
          } else {
            console.log('doc not exists');
          }
        })
      }, 1500);
    },
    async searchArticles() {
      console.log(this.lastarticleId)
      this.isSearchWarningClosed = false;
      this.setViewMode('search');
      this.searchResults = [];
      this.isSearching = true;
      try {
        await this.load_articles_and_categories(this.library_key, this.searchQuery);
        this.isSearching = false;
      } catch (err) {
        console.error(err);
      } finally {
        this.setSearches()
      }
    },
    setIsNavIconShown() {
      return this.viewMode === 'singleCategory' || this.viewMode === 'singleSubCategory' || this.viewMode === 'singleArticle' || this.viewMode === 'search';
    },
    handleNavIconClick() {
      if (this.viewMode === 'search') {
        this.setViewMode('all');
        this.searchQuery = '';
      }
      if (this.viewMode === 'singleCategory') {
        this.setViewMode(this.searchQuery ? 'search' : 'all');
      }
      if (this.viewMode === 'singleSubCategory') {
        this.setViewMode(this.searchQuery ? 'search' : 'singleCategory');
      }
      if (this.viewMode === 'singleArticle') {
        this.setViewMode(this.searchQuery ? 'search' : 'singleCategory');
      }
    },
    getSearchSubstr(content) {
      let splitContent = content.split('. ');

      let filteredContent = splitContent.filter(sentence => sentence.includes(this.searchQuery));

      if (filteredContent.length) {
        return filteredContent[0].replace(/<[^>]*>?/gm, '').slice(0, 100);
      }
      else {
        return splitContent[0].replace(/<[^>]*>?/gm, '').slice(0, 100);
      }
    },
    highlight(content) {
      if (!this.prevSearchQuery) return content;
      return content.replace(new RegExp(this.prevSearchQuery, "gi"), match => {
        return '<span class="highlighted">' + match + '</span>';
      });
    },
    closeWarning() {
      this.isSearchWarningClosed = true;
    },
    answerQuestion: async function (answer) {
      this.answer = answer;
      if (this.selectedArticle.id && answer !== '') {
        let article = await projectFirestore.collection('Articles').doc(this.selectedArticle.id).get();
        if (article.data()) {
          let obj = article.data()
          if (answer == 'yes') {
            obj.likes += 1;
            await projectFirestore.collection('Articles').doc(this.selectedArticle.id).update(obj);
          } else {
            obj.disLikes += 1;
            await projectFirestore.collection('Articles').doc(this.selectedArticle.id).update(obj);
          }
        }
      }
    },
    handleFormData() {
      this.contactFormData = {
        qualifyUserQuestion: this.qualifyUserQuestion,
        searchStep: this.searchStep,
        steps: this.steps,
        formSubmission: this.formSubmission
      }
    },

    initTranslations(dataElement) {
      if (dataElement) {
        this.translations.forEach((elem) => {
          const searchRegExp = new RegExp(`\\b${elem.source}\\b`, 'gi');
          dataElement = dataElement.replaceAll(searchRegExp, elem.translation);
        })
      }
      return dataElement

    },
    removeWidget() {
      // let doc = document.getElementById('widget-iframe').contentWindow
      // if (doc) {
      //   doc.postMessage('hello', 'hello')
      // }
      window.parent.postMessage(
        {
          event: 'close',
        },
        "*" //or "www.parentpage.com"
      )
    },

    scrollToTop() {
      window.scrollTo(0,0)
    },
  },
  beforeMount() {
    /* const router = useRouter();
    if(!this.$route.params.session_id){
      console.log("Session ID not available. Redirecting...");
      router.push({ name: 'Home_Session', params: { library_key: this.$route.params.library_key, session_id: "1232" } });
    } else {
      console.log("Session ID: " + this.$route.params.session_id);
    } */
  },
  mounted() {
    // this.scrollToTop()
    // console.log('scrolling')
    // console.log('parentURL', this.parentURL)

  },
  watch: {
    parentURL() {
    },
    viewMode: async function () {
      if (this.viewMode == 'singleArticle') {
        console.log(this.selectedArticle.id)
        let articlesViews = {
          articleId: this.selectedArticle.id,
          articleTitle: this.selectedArticle.article_title,
          view: 1,
          timestamp: moment().valueOf(),
          date: moment().format('L'),
          month: moment().format('MMMM'),
          year: moment().format('YYYY'),
        }
        this.support_lib_id = this.$route.params.library_key;
        console.log(this.support_lib_id);
        await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).get().then(async (doc) => {
          if (doc.exists) {
            await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('ARTICLESVIEWS').add(articlesViews);
            await projectFirestore.collection('Articles').doc(this.selectedArticle.id).get().then(async (res) => {
              if (res.exists) {
                let obj = res.data();
                obj.views += 1;
                await projectFirestore.collection('Articles').doc(this.selectedArticle.id).update(obj)
              }
            });
            const snapshot = await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('articlesViews').get();
            console.log('snapshot :-', snapshot.docs.map(doc => doc.data()));
          } else {
            console.log('doc not exists');
          }
        })

        if (this.successArticleSearchId) {
          await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').doc(this.successArticleSearchId).get().then(async (doc) => {
            if (doc.exists) {
              let obj = doc.data();
              obj.articlesReadId.push(this.selectedArticle.id)
              obj.articlesReadTitle.push(this.selectedArticle.article_title)
              await projectFirestore.collection('SupportLibraries').doc(this.support_lib_id).collection('SEARCHES').doc(this.successArticleSearchId).update(obj)
            }
          })
        }
      }
    },
    translations() {
      // console.log('translations', this.translations)
      if (this.translations.length) {
        this.isContentLoading = false
      }
    }
  }
};
</script>
