<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar is-blur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success" :dark-mode="true" />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="card-body">
                  <div class="text-center mb-3">
                    <img width="150" src="@/assets/img/modal_logo_dark.png" alt="" />
                  </div>
                  <ForgotPassword />
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <router-link :to="{ name: 'Admin' }" class="mx-auto mb-4 text-sm"> Login into your account </router-link>
                </div>
              </div>
            </div>
            <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden login-bg-container">
                <span class="mask bg-gradient-success opacity-0 bg-gradient-success-login"></span>

                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Knowledge grows exponentially. The more we know, the greater our ability to learn, and the faster we expand our knowledge base"</h4>
                <p class="text-white position-relative">~Dan Brown</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import getUser from '@/composables/getUser';
import ForgotPassword from '@/components/custom/ForgotPassword.vue';
import { useRouter } from 'vue-router';
const body = document.getElementsByTagName('body')[0];
export default {
  name: 'Forgot',
  components: {
    ForgotPassword,
  },
  setup() {
    const { user } = getUser();
    const router = useRouter();
    if (user.value.uid) {
      router.push({ name: 'Content' });
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    //this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    //this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  methods: {
    /* ...mapMutations(["toggleDefaultLayout"]), */
  },
};
</script>
<style>
.login-bg-container {
  /* background: url('@/assets/img/login-bg.png'); */
  background: url('@/assets/img/login-bg-techno.png');
  background-size: cover;
}
</style>
