/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import '@/assets/css/nucleo-icons.css';
import '@/assets/css/nucleo-svg.css';
import VueTilt from 'vue-tilt.js';
import VueSweetalert2 from 'vue-sweetalert2';
import ArgonDashboard from '@/argon-dashboard';
import VueAwesomePaginate from 'vue-awesome-paginate';
import '@/assets/custom_global.css';
import PrimeVue from 'primevue/config';
// import 'primevue/resources/themes/lara-light-indigo/theme.css';
// import 'primevue/resources/primevue.min.css';
import Skeleton from 'primevue/skeleton';
import MultiSelect from 'primevue/multiselect';
import Select from 'primevue/select';
import Aura from '@primevue/themes/aura';
// import "vue-awesome-paginate/dist/style.css";
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import firebase auth service
import { projectAuth } from '@/firebase/config';
/// fontawesome modules
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Popper from "vue3-popper";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"
import {
  // Directives
  vTooltip,
  vClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu
} from 'floating-vue'



library.add(fas);

library.add(far);

let appInstance;

projectAuth.onAuthStateChanged(() => {
  if (!appInstance) {
    appInstance = createApp(App);
    appInstance.use(store);
    appInstance.use(router);
    appInstance.component('tooltip', vTooltip)
    appInstance.use(VueTilt);
    appInstance.use(VueSweetalert2);
    appInstance.use(PrimeVue, {
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: 'none',
      }
      }
    });
    appInstance.use(ArgonDashboard);
    appInstance.component('Skeleton', Skeleton);
    appInstance.component('Select', Select);
    appInstance.component('MultiSelect', MultiSelect);
    appInstance.component("Popper", Popper);
    appInstance.use(Toast, {
      transition: "Vue-Toastification__bounce",
      newestOnTop: false
    });
    appInstance.use(VueAwesomePaginate);
    appInstance.mount('#app');
    appInstance.component('font-awesome-icon', FontAwesomeIcon);
  }
});
